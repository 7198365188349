import * as React from "react"
import Layout from "./layout"
import ServiceDetail from "../components/service-detail"
import { AnchorLink } from "gatsby-plugin-anchor-links";
const HairAndBeautyPage = () => {
    const style = {
        textAlign: "center"
    };

    const details = [
        {
            id: "waxing",
            title: "WAXING/ HAIR REMOVAL",
            imagePath: {
                src1: "/wp-content/uploads/2016/10/waxing-leg-catalog.jpg",
                src2: "/wp-content/uploads/2016/10/waxing-eyebrow-catalog.jpg",
                src3: "/wp-content/uploads/2016/10/waxing-back-catalog.jpg",
                src4: "/wp-content/uploads/2016/10/lady-laser-smile-catalog.jpg",
            },
            services: [
                "Eye Brow",
                "Upper Lip", "Chin",
                "Side Face", "Full Face",
                "Full Arm", "Under Arm", "Full Leg",
                "Full Legs With Bikini",
                "Full Legs With Brazilian", "Half Leg", "Stomach", "Full Back", " Brazilian", "Bikini Line", "Full Body"
            ]
        }, {
            id: "laser",
            title: "LASER HAIR REMOVAL",
            imagePath: {
                src1: "/wp-content/uploads/2016/10/lady-running-beach-catalog.jpg",
                src2: "/wp-content/uploads/2016/10/lady-seating-catalog.jpg",
                src3: "/wp-content/uploads/2016/10/men-laser-catalog.jpg",
                src4: "/wp-content/uploads/2016/10/laser-soprano-catalog.jpg",
            },
            services: [
                "Chin & Upper Lip",
                "Side Burns",
                "Neck",
                "Ears",
                "Full Face",
                "Half Face",
                "Under Arm",
                "Hands & Finger",
                "Abdomen",
                "Upper Back",
                "Full Back",
                "Lower Back",
                "Shoulder",
                "Full Legs",
                "Half Legs",
                "French Bikini",
                "Bikini Line",
                "Brazilian",
                "Chest",
                "Buttocks",
            ]
        }, {
            id: "facial",
            title: "FACIAL",
            imagePath: {
                src1: "/wp-content/uploads/2016/10/facial-laday-brush-280px.jpg",
                src2: "/wp-content/uploads/2016/10/lady-face-smile-300px.jpg",
                src3: "/wp-content/uploads/2016/10/men-facial-catalog.jpg",
                src4: "/wp-content/uploads/2016/10/women-facial-face-catalog.jpg",
            },
            services: [
                "Anti Aging",
                "Acne Facial",
                "Hydrafacial",
                "Dermalogica Facial",
                "Gold Facial",
                "Pearl Facial",
                "Fruit Facial",
                "Express Facial",
            ]
        }, {
            id: "ladies",
            title: "LADIES HAIR CARE",
            imagePath: {
                src1: "/wp-content/uploads/2016/10/ladies-hair-curly-catalog.jpg",
                src2: "/wp-content/uploads/2016/10/ladies-hair-cut-catalog.jpg",
                src3: "/wp-content/uploads/2016/10/ladies-hair-lying-catalog.jpg",
                src4: "/wp-content/uploads/2016/10/ladies-hair-wash-catalog.jpg",
            },
            services: [
                "Hair Cut",
                "Wash & Style",
                "Wash, Cut & Style",
                "Hair Color",
                "Re-Touch",
                "Foil High Light",
                "HighLights",
                "Cap-HighLights",
                "Hair Relaxer",
                "Perm",
            ]
        }, {
            id: "mens",
            title: "Men's Hair Care",
            imagePath: {
                src1: "/wp-content/uploads/2016/10/men-hair-cut-catalog.jpg",
                src2: "/wp-content/uploads/2016/10/men-hair-style-catalog.jpg",
                src3: "/wp-content/uploads/2016/10/kids-hair-cut-catalog.jpg",
                src4: "/wp-content/uploads/2016/10/men-hair-wash-catalog.jpg",
            },
            services: ["Hair Cut", "Wash & Cut", " Kids Hair Cut", "Hair Colour", "High Light", "Perm"]
        }];

    let serviceList = [];

    details.forEach((detail, index) => {
        serviceList.push(<ServiceDetail detail={detail} key={index} />);
    });

    return (

        <Layout>
            <div className="container">
                <div className="dt-sc-hr-invisible-small  "></div>
                <h1 className="hr-title dt-page-title">
                    <span>Hair &amp; Beauty	</span>
                </h1>
                <div className="dt-sc-hr-invisible-small  "></div>
                <div className="dt-sc-clear"></div>
                <section id="primary" className="content-full-width">
                    <div id="post-26" className="post-26 page type-page status-publish hentry">
                        <p style={style}>
                            <AnchorLink to="/hair-beauty#waxing" title="Waxing" className="dt-sc-button medium" />&nbsp;
                            <AnchorLink to="/hair-beauty#laser" title="Laser hair removal" className="dt-sc-button medium" />&nbsp;
                            <AnchorLink to="/hair-beauty#facial" title="Facial" className="dt-sc-button medium" />&nbsp;
                            <AnchorLink to="/hair-beauty#ladies" title="Ladies Hair Care" className="dt-sc-button medium" />&nbsp;
                            <AnchorLink to="/hair-beauty#mens" title="Men&nbsp;Hair Care" className="dt-sc-button medium" />
                        </p>
                        {serviceList}
                    </div>
                </section>
            </div>
        </Layout>
    )
}

export default HairAndBeautyPage
