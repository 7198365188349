import React from "react"

export default function ServiceDetail(props) {
    let serviceList = [];

    props.detail.services.forEach((service, index) => {
        serviceList.push(<li>
            <a href="" target=""><span className="fa "></span>
                <span className="item-name">{service}</span></a>
        </li>);
    });

    return (
        <div  >
            <h2 className="dark-title hr-title"><span>{props.detail.title}</span></h2>
            <p>{props.detail.description}</p>
            <div ><div className="dt-sc-hr-invisible-small  "></div></div>
            <div id={props.detail.id} className="column dt-sc-one-half  first"><div className="column dt-sc-one-half  first">
                <img src={props.detail.imagePath.src1} alt="" width="281" height="187"  /></div>
                <div className="column dt-sc-one-half  ">
                    <img src={props.detail.imagePath.src2} alt="" width="225" height="187"  /></div>
                <div className="dt-sc-clear"></div>
                <div className="column dt-sc-one-half  first">
                    <img src={props.detail.imagePath.src3} alt="" width="225" height="187"  /></div>
                <div className="column dt-sc-one-half  ">
                    <img src={props.detail.imagePath.src4} alt="" width="225" height="187"  /></div>
            </div>
            <div className="column dt-sc-one-half  ">
                <ul className="dt-sc-specials-list">
                    <li><span className="item-name"><strong>Services</strong></span></li>
                    {serviceList}
                </ul>
                <div className="dt-sc-clear"></div></div>

        </div>

    )
};